<template>
  <div class="part-nine">
    <div class="title">
      <div style="font-size: 40px; color: #000000; font-weight: bold">
       芸众最新动态 
      </div>
    </div>
    <div class="trends-list">
      <div class="trends-list-item" v-for="(item,index) in trendsList" :key="index">
         <div class="trends-list-item-top" :style="{backgroundImage: `url(${item.bgImg})`}">
          <div class="top-content">
            <div style="color:#333333;font-weight: 300;font-size:20px">{{item.esTitle}}</div>
            <div class="trend-line"></div>
            <div style="color:#333333;font-size:28px;font-weight: bold;margin-top:8px">{{item.title}}</div>
          </div>
         </div>
         <div class="trends-list-item-content">
            <div style="color:#333333;font-size:18px;cursor: pointer;height: 48px;" @click="toUrl(item.middleUrl)">{{item.middleTitle}}</div>
            <div style="margin-top:12px;color: rgba(51,51,51,0.7);font-size:14px">{{item.middleDes}}</div>
            <div style="width: 336px;height: 1px;background-color:rgba(51,51,51,0.3);margin-top:15px;margin-bottom:20px"></div>
            <div class="version-list-item" v-for="(items,indexs) in item.versionList" :key="indexs">
                <div style="color: rgba(51,51,51,0.7);font-size:14px;display: inline-block;">{{items.data}}</div>
                <div :title="items.versionTitle" class="new-content" style="" @click="toUrl(items.url)">{{items.versionTitle}}</div>
            </div>
         </div>
      </div>
    </div>     
  </div>
</template>

<script>
export default {
  data () {
    return {
      //芸众最新动态列表 
      trendsList:[
        {
          bgImg:require('../assets/part-nine-bg1.png'),
          esTitle:'Functional updates',
          title:'功能更新',
          middleTitle:'功能 | 【多平台订单转换】上线，实现流量公转私',
          middleDes:'用户在第三方平台消费，在私域商城也能获得奖励。企业借助【多平台订单转化】功能，可以将在第三方（如抖音、淘宝等）平台成交的流量有效引导到私域商城，实现流量的高效转化。',
          middleUrl:'https://www.yunzmall.com/plugins/shop_server/articleContent/645?i=10',
          versionList:[
            {
              data:'03-26',
              versionTitle:'优化 | 中台选品中心增加PC前端选品能力',
              url:'https://www.yunzmall.com/plugins/shop_server/articleContent/646?i=10'
            },
            {
              data:'03-19',
              versionTitle:'功能 | 【工猫提现】上线，灵活用工代发薪',
              url:'https://www.yunzmall.com/plugins/shop_server/articleContent/643?i=10'
            },
            {
              data:'03-12',
              versionTitle:'功能 | 【有效期积分】上线，用户资产营销',
              url:'https://www.yunzmall.com/plugins/shop_server/articleContent/640?i=10'
            },
            {
              data:'03-06',
              versionTitle:'功能 | 【扫码点餐】上线，用户到店自助点餐支付',
              url:'https://www.yunzmall.com/plugins/shop_server/articleContent/638?i=10'
            }                                    
          ]
        },
        {
          bgImg:require('../assets/part-nine-bg2.png'),
          esTitle:'Company News',
          title:'公司动态',
          middleTitle:'直播课程 | 行业模式和销售策略全面拆解',
          middleDes:'芸众每周三场直播，全面拆解商业升级策略，助力企业实现亿级增长目标。这次，我们对知识付费模式、CPS联盟模式、咨询电商模式进行深度解析，帮助行业加速数字化转型，实现量效双增。',
          middleUrl:'https://www.yunzmall.com/plugins/shop_server/articleContent/644?i=10',
          versionList:[
            {
              data:'03-19',
              versionTitle:'直播课程 | 零售新时代，门店数字破局',
              url:'https://www.yunzmall.com/plugins/shop_server/articleContent/642?i=10'
            },
            {
              data:'03-12',
              versionTitle:'直播课程 | 市场主流电商模式拆解',
              url:'https://www.yunzmall.com/plugins/shop_server/articleContent/639?i=10'
            },
            {
              data:'03-06',
              versionTitle:'直播课程 | 揭秘供应链中台的进阶之路',
              url:'https://www.yunzmall.com/plugins/shop_server/articleContent/637?i=10'
            },
            {
              data:'02-27',
              versionTitle:'直播课程 | 企业用起来，中台活起来',
              url:'https://www.yunzmall.com/plugins/shop_server/articleContent/632?i=10'
            }                                  
          ]
        } ,
        {
          bgImg:require('../assets/part-nine-bg3.png'),
          esTitle:'Industry News',
          title:'行业新闻',
          middleTitle:'资讯 | 视频号小店商家微会员功能上线；抖音电商暂停抖客平台运营',
          middleDes:'每周一分钟，打破信息差！来看看这个星期，电商圈又发生了哪些你不能错过的大事件。',
          middleUrl:'https://www.yunzmall.com/plugins/shop_server/articleContent/641?i=10',
          versionList:[
            {
              data:'03-01',
              versionTitle:'资讯 | 阿里云全线下调云产品官网售价；阿里妈妈发布开年领跑三大计划', 
              url:'https://www.yunzmall.com/plugins/shop_server/articleContent/635?i=10'
            },             
            {
              data:'02-23',
              versionTitle:'资讯 | 抖音将价格力定为24年最高任务；淘宝成立直播电商公司',
              url:'https://www.yunzmall.com/plugins/shop_server/articleContent/630?i=10'
            },
            {
              data:'02-08',
              versionTitle:'资讯 | 支付宝要求商家3月31日前完成小程序备案；抖音上线达人选品价格标签',
              url:'https://www.yunzmall.com/plugins/shop_server/articleContent/624?i=10'
            },
            {
              data:'02-04',
              versionTitle:'资讯 | 抖音上线旗舰商家专区；天猫新增封号必承诺服务赔',
              url:'https://www.yunzmall.com/plugins/shop_server/articleContent/620?i=10'
            },                                   
          ]
        }                
      ]
    };
  },

  methods: {
    toUrl(url){
      window.open(url)
    }
  },

  components: {},
};

</script>
<style  scoped>
  .part-nine{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; 
  padding-bottom: 112px;
  background-image: url('../assets/part-nine-bg.png');    
  }
  .title{
  margin-top: 56px;
}
.trends-list{
  margin-top: 95px;
  display: flex;
}
.trends-list-item{
  background-color: #FFFFFF;
  width: 388px;
  border-radius: 4px;
}
.trends-list-item:nth-child(2){
  margin:0 18px
}
.trends-list-item:hover{  
box-shadow: 0px 13px 20px 1px rgba(117,139,185,0.2);
}
.trends-list-item-top{
 height: 179px;
 width: 388px;
}
.top-content{
  padding: 45px 0 0 40px;
}
.trend-line{
  width: 40px;
  height: 6px;
  background: #2A7EFB;
}

.trends-list-item-content{
  padding: 20px 26px 26px 26px;
}
.version-list-item{
  margin-top: 15px;
}
.new-content{
margin-left:11px;
font-size:14px;
color:#333333;
cursor: pointer;
display: inline-block;
width: 274px;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
line-height: 13px;
}
</style>